import * as React from "react"

import Layout from "../components/shared/layout"
import Seo from "../components/shared/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className='text-center h-100vh w-100 flex align-center'>
      <div className="">
        <h1 className='m0 mb20'>404: not found</h1>
        <p className='mb20'>you just hit a route that doesn&#39;t exist... the sadness.</p>
        <a href='/'>return to homepage</a>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
